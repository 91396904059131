import TinyMenu_TinyMenuSkinComponent from '../components/TinyMenu/viewer/skinComps/TinyMenuSkin/TinyMenuSkin.skin';
import TinyMenu_TinyMenuSkinController from '../components/TinyMenu/viewer/TinyMenu.controller';


const TinyMenu_TinyMenuSkin = {
  component: TinyMenu_TinyMenuSkinComponent,
  controller: TinyMenu_TinyMenuSkinController
};


export const components = {
  ['TinyMenu_TinyMenuSkin']: TinyMenu_TinyMenuSkin
};

