import classNames from 'clsx';
import React from 'react';
import { Keys, TestIds } from '../constants';
import { ITinyMenuItemProps } from '../TinyMenu.types';
import TinyMenuMembersArea from './members/TinyMenuMembersArea';
import { SkinTinyMenuWrapperProps } from './skinComps/SkinTinyMenu';
import TinyMenuItem from './TinyMenuItem';
import {
  getSubItemsWithClickHandler,
  hasSelectedSubItem,
} from './utils/ItemBehavior';
import TinyMenuLanguageSection from './multilingual/TinyMenuLanguageSection';

type TinyMenuContainerProps = Omit<
  SkinTinyMenuWrapperProps,
  | 'items'
  | 'currentPrimaryPageHref'
  | 'activeAnchor'
  | 'ariaLabel'
  | 'isOpen'
  | 'setIsOpen'
> & {
  ref?: React.RefObject<HTMLDivElement>;
  items: Array<ITinyMenuItemProps>;
  hideSubmenuHeader?: boolean;
  skinsStyle: Record<string, string>;
  separateIconsSection?: boolean;
  currentHref?: string;
  currentAnchor?: string;
  onItemClick: (item: ITinyMenuItemProps) => void;
};

const TinyMenuContainer: React.ForwardRefRenderFunction<
  HTMLDivElement,
  TinyMenuContainerProps
> = (
  {
    id,
    items,
    members,
    hideSubmenuHeader,
    skinsStyle,
    separateIconsSection = true,
    textAlignment,
    onLogin,
    onLogout,
    onItemClick,
    isLoggedIn,
    userName,
    avatarUri,
    currentHref,
    currentAnchor,
    languages,
    onLanguageChange,
  },
  ref,
) => {
  const handleLanguageChange = (value: string) =>
    onLanguageChange && onLanguageChange(value);
  return (
    <div ref={ref} className={skinsStyle.menuContainer}>
      {!!members && (
        <TinyMenuMembersArea
          skinsStyle={skinsStyle}
          separateIconsSection={separateIconsSection}
          textAlignment={textAlignment}
          className={classNames(
            skinsStyle.topMenu,
            skinsStyle.firstSection,
            skinsStyle.memberSection,
            skinsStyle.open,
          )}
          onLogin={onLogin}
          onLogout={onLogout}
          isLoggedIn={isLoggedIn}
          userName={userName}
          avatarUri={avatarUri}
          currentHref={currentHref}
          currentAnchor={currentAnchor}
          {...members}
        />
      )}
      <ul
        id={`menuItems${id}`}
        className={classNames(skinsStyle.topMenu, skinsStyle[textAlignment], {
          [skinsStyle.firstSection]: !members,
          [skinsStyle.nthSection]: !!members,
        })}
      >
        {items.map((item, index) => (
          <React.Fragment key={`${Keys.item}-${index}`}>
            <TinyMenuItem
              {...item}
              items={getSubItemsWithClickHandler(item, onItemClick)}
              initialIsOpen={hasSelectedSubItem(item.items)}
              hideSubmenuHeader={hideSubmenuHeader}
              isCurrentHref={item.link?.href === currentHref}
              skinsStyle={skinsStyle}
              dataTestId={`${TestIds.item}-${index}`}
              onClick={() => onItemClick(item)}
            />
          </React.Fragment>
        ))}
      </ul>
      {languages && languages.length > 1 && (
        <TinyMenuLanguageSection
          onChange={handleLanguageChange}
          options={languages}
          className={classNames(skinsStyle.topMenu, skinsStyle.open)}
        />
      )}
    </div>
  );
};

export default React.forwardRef(TinyMenuContainer);
