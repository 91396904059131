import { withCompController } from '@wix/editor-elements-integrations';
import {
  AnalyticsClicksGroups,
  tryReportAnalyticsClicksBi,
} from '@wix/editor-elements-common-utils';
import {
  ITinyMenuControllerActions,
  ITinyMenuMapperProps,
  ITinyMenuProps,
  ITinyMenuStateValues,
} from '../TinyMenu.types';

export default withCompController<
  ITinyMenuMapperProps,
  ITinyMenuControllerActions,
  ITinyMenuProps,
  ITinyMenuStateValues
>(({ controllerUtils, mapperProps, stateValues }) => {
  const { reportBi } = stateValues;
  const {
    compId,
    language,
    mainPageId,
    fullNameCompType,
    trackClicksAnalytics,
    ...restMapperProps
  } = mapperProps;

  const commonAnalyticsClicksBiParams = {
    language,
    trackClicksAnalytics,
    element_id: compId,
    elementType: fullNameCompType,
    details: { skin: restMapperProps.skin },
    elementGroup: AnalyticsClicksGroups.Menu,
  };

  const reportBiOnMenuButtonClick: ITinyMenuControllerActions['reportBiOnMenuButtonClick'] =
    () => {
      tryReportAnalyticsClicksBi(
        stateValues.reportBi,
        commonAnalyticsClicksBiParams,
      );
    };

  const reportBiOnMenuItemClick: ITinyMenuControllerActions['reportBiOnMenuItemClick'] =
    item => {
      const { label, link } = item ?? {};

      tryReportAnalyticsClicksBi(reportBi, {
        ...commonAnalyticsClicksBiParams,
        link,
        elementTitle: label,
        pagesMetadata: { mainPageId },
      });
    };

  return {
    ...restMapperProps,
    reportBiOnMenuButtonClick,
    reportBiOnMenuItemClick,
    setIsOpen: isOpen => {
      controllerUtils.updateProps({ isOpen });
    },
  };
});
