import { TinyMenuIconItemProps } from '@wix/thunderbolt-components';
import * as React from 'react';
import { IIconProps } from '@wix/thunderbolt-elements/src/components/Icon/Icon.types';
import Icon from '@wix/thunderbolt-elements/src/components/Icon/viewer/Icon';
import LoginSocialButton from '@wix/thunderbolt-elements/src/components/LoginSocialButton/viewer/LoginSocialButton';
import { ICON_SIZE, Ids, TestIds } from '../../constants';
import {
  ITinyMenuMembersFeatureProps,
  ITinyMenuProps,
} from '../../TinyMenu.types';
import { getItemsWithSelection } from '../utils/ItemBehavior';
import TinyMenuLoginButton from './TinyMenuLoginButton';
import TinyMenuMembersMenu from './TinyMenuMembersMenu';

type ITinyMenuMembersProps = ITinyMenuProps['members'];

type ITinyMenuMembersAreaProps = ITinyMenuMembersProps &
  ITinyMenuMembersFeatureProps & {
    className: string;
    separateIconsSection: boolean;
    skinsStyle: Record<string, string>;
    currentHref?: string;
    currentAnchor?: string;
  } & Pick<ITinyMenuProps, 'textAlignment'>;

const getIconItemProps = (
  svgContent: string,
  skinsStyle: Record<string, string>,
  index: number,
): IIconProps => ({
  id: `tiny-menu-icon-${index}`,
  svgContent,
  size: ICON_SIZE,
  className: skinsStyle.iconSvg,
});

const renderIconsSection = ({
  displayMode,
  textAlignment,
  skinsStyle,
  avatarUri,
  defaultAvatarSvgContent,
  isLoggedIn,
  iconItems,
}: Pick<
  ITinyMenuMembersAreaProps,
  | 'displayMode'
  | 'textAlignment'
  | 'skinsStyle'
  | 'avatarUri'
  | 'defaultAvatarSvgContent'
  | 'isLoggedIn'
  | 'iconItems'
>) => {
  const isLeftAligned = textAlignment === 'left';
  const displayAvatar =
    displayMode === 'avatarAndText' || displayMode === 'avatarOnly';
  const sortedIconItems = isLeftAligned ? iconItems.reverse() : iconItems;
  const avatar = displayAvatar && (
    <Icon
      id={Ids.membersAreaAvatar}
      size={ICON_SIZE}
      imageContainerId={Ids.membersIconSectionId}
      imageUri={avatarUri}
      className={skinsStyle.avatar}
      svgContent={defaultAvatarSvgContent}
      renderImage={isLoggedIn}
    />
  );

  return (
    <li id={Ids.membersIconSectionId} className={skinsStyle.memberIconItems}>
      {isLeftAligned && avatar}
      {sortedIconItems.map(
        (
          { label, displayCount, link, iconSvgContent }: TinyMenuIconItemProps,
          index: number,
        ) => (
          <LoginSocialButton
            className={skinsStyle.icon}
            dataTestId={`${TestIds.membersButton}-${index}`}
            key={index}
            link={link}
            icon={getIconItemProps(iconSvgContent, skinsStyle, index)}
            label={label}
            displayCount={displayCount}
            direction={isLeftAligned ? 'ltr' : 'rtl'}
          />
        ),
      )}
      {!isLeftAligned && avatar}
    </li>
  );
};

const getMergedMenuItems = ({
  menuItems,
  iconItems,
  separateIconsSection,
}: Pick<
  ITinyMenuMembersAreaProps,
  'iconItems' | 'menuItems' | 'separateIconsSection'
>) => {
  return separateIconsSection ? menuItems : [...menuItems, ...iconItems];
};

const TinyMenuMembersArea: React.FC<ITinyMenuMembersAreaProps> = ({
  className,
  isLoggedIn,
  separateIconsSection,
  menuItems,
  currentHref,
  currentAnchor,
  skinsStyle,
  loggedOutText,
  userName,
  loggedInText,
  logOutText,
  onLogin,
  onLogout,
  iconItems,
  ...rest
}) => {
  const handleLogin = () => onLogin && onLogin();
  const handleLogout = () => onLogout && onLogout();
  const mergedMenuItems = getMergedMenuItems({
    menuItems,
    iconItems,
    separateIconsSection,
  });
  const mergedMenuItemsWithSelection = React.useMemo(
    () => getItemsWithSelection(mergedMenuItems, currentHref, currentAnchor),
    [mergedMenuItems, currentHref, currentAnchor],
  );

  return (
    <div data-testid={TestIds.membersArea}>
      <ul
        className={className}
        // TODO - this is probably handled by the viewer - is it really needed ?
        data-icon-size={ICON_SIZE}
        data-is-logged-in={isLoggedIn}
      >
        {separateIconsSection &&
          renderIconsSection({
            skinsStyle,
            isLoggedIn,
            iconItems: isLoggedIn ? iconItems : [],
            ...rest,
          })}
        {isLoggedIn ? (
          <TinyMenuMembersMenu
            skinsStyle={skinsStyle}
            userName={userName || ''}
            loggedInText={loggedInText || ''}
            logOutText={logOutText}
            items={mergedMenuItemsWithSelection}
            onLogout={handleLogout}
          />
        ) : (
          <TinyMenuLoginButton
            skinsStyle={skinsStyle}
            label={loggedOutText}
            onClick={handleLogin}
          />
        )}
      </ul>
    </div>
  );
};

export default TinyMenuMembersArea;
